import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap, share, debounceTime } from 'rxjs/operators';
// export const endpoint = 'http://localhost:3100/';
export const endpoint = 'https://mogenitea.com:3200/';
@Injectable()
export class AuthService {
  constructor(public router: Router,private http: HttpClient) {
  }
  
  private extractData(res) {
    const  body = res;
    return body || { };
    }
  signupUser(email: string, password: string) {
  }

  signinUser(data): Observable<any> {
    return this.http.post(endpoint + 'users/login',data).pipe(
      map(this.extractData));
    }

  logout() {
    this.router.navigate(['YOUR_LOGOUT_URL']);
  }
  isAuthenticated() {
    return true;
  }
}
